import React from 'react';
import Layout from "../layout/layout.component";
import {graphql} from "gatsby";
import SEO from "../partials/seo/seo.component";
import { Index } from "elasticlunr";
import Category from "../partials/category/category.component";
import {Row} from "react-bootstrap";
import SearchClass from "../partials/search/SearchClass.component";

const CONFIG = require("../configs/main");

class SearchPage extends SearchClass {
    componentDidMount() {
        this.search();
        document.getElementById("search-input-page").focus();
    }

    getOrCreateIndex() {
        return this.index ? this.index : Index.load(this.props.data.siteSearchIndex.index);
    }

    scrollToResults() {
        document.querySelector("#search-results").scrollIntoView();
    }

    render() {
        const { schema } = this.props.pageContext;
        let value = unescape(this.state.query);

        return (
            <Layout isSearch={true}>
                {schema ? (<SEO data={schema}/>) : null}
                <Row className="container justify-content-center">
                    <div className="col-12 col-md-6 col-lg-9 px-0">
                        <div className="col-12 col-md-12 col-lg-7 px-0">
                            <h1 className="search-title">Search in all articles</h1>
                            <input className="search-input"
                                   type="text"
                                   aria-label="search"
                                   id="search-input-page"
                                   onChange={this.search.bind(this)}
                                   autoComplete="off"
                                   placeholder="Search"
                                   value={value}
                                   onKeyPress={event => {
                                       if (event.key === "Enter") {
                                           this.scrollToResults()
                                       }
                                   }}
                            />
                        </div>
                        <div className="col-12 px-0" id="search-results">
                            { this.state.query &&
                            <div className="search-results-wrapper">
                                <span className="search-results">Search results contianing "</span>
                                <span className="search-results-orange">{value}</span>
                                <span className="search-results">":</span>
                            </div>
                            }
                            {this.state.articlesCount === 0 &&
                                <div>
                                    <div className="search-results-wrapper">
                                        <span className="search-results">Sorry, I couldn't find anything</span>
                                    </div>
                                    <div>
                                        <svg className="search-no-results-desktop">
                                            <use xlinkHref="/images/desktop-nosearchresults.svg#search"/>
                                        </svg>
                                        <svg className="search-no-results-mobile">
                                            <use xlinkHref="/images/mobile-nosearchresults.svg#search"/>
                                        </svg>
                                    </div>
                                </div>
                            }
                            {this.state.filteredResults && this.state.filteredResults.edges &&
                                <Category data={ this.state.filteredResults.edges }/>
                            }
                            {this.state.articlesCount > CONFIG.category_pagination_count && this.state.last_position < this.state.articlesCount &&
                            <div className="search-load-more">
                                <a href="#" onClick={this.addArticlesToArray.bind(this)}>LOAD MORE</a>
                            </div>
                            }
                        </div>
                    </div>
                </Row>
            </Layout>
        );
    }
}
export default SearchPage

export const pageQuery = graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
`;

